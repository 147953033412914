import { animated } from '@react-spring/web'
import styled from 'styled-components'

export const HeaderGrid = styled.div<{ optionCount: number }>`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(${(props) => props.optionCount}, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;

  /* height: 58px; */
`

export const AnimatedHeaderGrid = animated(HeaderGrid)

type IconProps = React.SVGAttributes<SVGElement> & {
  active: boolean
}

const getFillColor = (active: boolean): string => {
  const fillColor = active ? '#080A0C' : '#FFFFFF'
  return fillColor
}

export function RedoIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fillColor}
        d="M10.07 7c2.161 0 4.278.729 6 2.017V7h2v5a1 1 0 01-1 1h-5v-2h3.278a8.058 8.058 0 00-5.278-2 8.055 8.055 0 00-5.656 2.343L3 9.929A10.066 10.066 0 0110.07 7z"
      ></path>
    </svg>
  )
}

export function UndoIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fillColor}
        d="M10 7a10.07 10.07 0 00-6 2.017V7H2v5a1 1 0 001 1h5v-2H4.722A8.058 8.058 0 0110 9a8.06 8.06 0 015.657 2.343l1.414-1.414A10.068 10.068 0 0010 7z"
      ></path>
    </svg>
  )
}

export function MoreIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={'#080A0C'}
        // fill={fillColor}
        fillRule="evenodd"
        d="M4 12a2 2 0 100-4 2 2 0 000 4zm6 0a2 2 0 100-4 2 2 0 000 4zm8-2a2 2 0 11-4 0 2 2 0 014 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export function HandIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fillColor}
        d="M17 4a1 1 0 00-1 1v4a.5.5 0 01-1 0V3.553a1.037 1.037 0 00-.832-1.04A1 1 0 0013 3.5V9a.5.5 0 01-1 0V3.053a1.037 1.037 0 00-.832-1.039A1 1 0 0010 3v6a.5.5 0 01-1 0V3.553a1.037 1.037 0 00-.832-1.039A1 1 0 007 3.5v7.793a.5.5 0 01-.854.354l-2-2A1.08 1.08 0 002.91 9.4a1.032 1.032 0 00-.442 1.434l2.31 4.108A5.999 5.999 0 0010.01 18h1.99a6 6 0 006-6V5a1 1 0 00-1-1z"
      ></path>
    </svg>
  )
}

export function TextIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path fill={fillColor} d="M2 2v6h1l2-3h3v10l-3 2v1h10v-1l-3-2V5h3l2 3h1V2H2z"></path>
    </svg>
  )
}

export function EraserIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M11.4 14H6.6l-3.3-3.3c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0l5 5c.4.4.4 1 0 1.4L11.4 14zM2 16h16v2H2v-2z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export function PaintBucketIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M12.7 2.6l4.9 6.7c.3.3.7 1.1.1 2.4-.4.8-1 1.6-1.7 2.3-1.1 1.1-2.5 2-3.6 2-.4 0-.8-.1-1.1-.4l-6.7-4.9c-1.4-1.4.1-4.2 2-6.1 1-1 2.1-1.8 3.1-2.2 1.7-.7 2.6-.2 3 .2zM11.2 4c-.4 0-1.7.5-3.2 2-1.5 1.4-1.9 2.7-2 3.2.4 0 1.7-.5 3.2-2 1.5-1.5 2-2.8 2-3.2zM4 12s-2 2.9-2 4 .9 2 2 2 2-.9 2-2-2-4-2-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export function BrushIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)
  const { width = 20, height = 20 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M8 9l3 3 6.4-6.4c.8-.8.8-2.2 0-3-.8-.8-2.2-.8-3 0L8 9zm-.4 3.3c-1.1-1.2-3-1.2-4.2 0C2.2 13.5 2 18 2 18s4.4-.4 5.6-1.5c1.2-1.1 1.1-3 0-4.2z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export function CloseIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill={fillColor}
        d="M14.2 2.9L10 7.2 5.8 2.9 2.9 5.8 7.2 10l-4.3 4.2 2.9 2.9 4.2-4.3 4.2 4.3 2.9-2.9-4.3-4.2 4.3-4.2-2.9-2.9z"
      ></path>
    </svg>
  )
}

export function ExitIcon(props: IconProps) {
  const fillColor = getFillColor(props.active)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill={fillColor}
        d="M7 16h8V4H7a1 1 0 010-2h8a2 2 0 012 2v12a2 2 0 01-2 2H7a1 1 0 010-2z"
      ></path>
      <path
        fill="#fff"
        d="M3.293 9.292l3-3a1 1 0 011.414 1.414L6.414 9H12a1 1 0 110 2H6.414l1.293 1.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414z"
      ></path>
    </svg>
  )
}

export function ShareIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#fff"
        d="M12.835 2.129A.5.5 0 0012 2.5v2.517A6.187 6.187 0 006 11.5a.5.5 0 00.92.272A5.9 5.9 0 0112 9.013V11.5a.5.5 0 00.835.372l5-4.5a.499.499 0 000-.744l-5-4.5z"
      ></path>
      <path
        fill="#fff"
        d="M17 11a1 1 0 00-1 1v4H4V6h2a1 1 0 000-2H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2v-4a1 1 0 00-1-1z"
      ></path>
    </svg>
  )
}

export function DownloadImageIcon({ size = '20' }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4 16V4h12v6h2V3a1 1 0 00-1-1H3a1 1 0 00-1 1v14a1 1 0 001 1h7v-2H4zm4-8a1 1 0 100-2 1 1 0 000 2zm5.316 2.632A6.02 6.02 0 0010.34 14H5l2-4 2 2 3-4 1.316 2.632z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M19.875 15.829l-3.5 4a.5.5 0 01-.752 0l-3.5-4A.5.5 0 0112.5 15H15v-2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V15h2.5a.5.5 0 01.375.829z"
      ></path>
    </svg>
  )
}

export function LightModeIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      x="0"
      y="0"
      enableBackground="new 0 0 45.16 45.16"
      version="1.1"
      viewBox="0 0 45.16 45.16"
      xmlSpace="preserve"
    >
      <path
        fill="#fff"
        d="M22.58 11.269c-6.237 0-11.311 5.075-11.311 11.312s5.074 11.312 11.311 11.312c6.236 0 11.311-5.074 11.311-11.312S28.816 11.269 22.58 11.269zM22.58 7.944a2.207 2.207 0 01-2.207-2.206V2.207a2.207 2.207 0 114.414 0v3.531a2.207 2.207 0 01-2.207 2.206zM22.58 37.215a2.207 2.207 0 00-2.207 2.207v3.53a2.207 2.207 0 104.414 0v-3.53a2.208 2.208 0 00-2.207-2.207zM32.928 12.231a2.208 2.208 0 010-3.121l2.497-2.497a2.207 2.207 0 113.121 3.121l-2.497 2.497a2.207 2.207 0 01-3.121 0zM12.231 32.93a2.205 2.205 0 00-3.121 0l-2.497 2.496a2.207 2.207 0 003.121 3.121l2.497-2.498a2.204 2.204 0 000-3.119zM37.215 22.58c0-1.219.988-2.207 2.207-2.207h3.531a2.207 2.207 0 110 4.413h-3.531a2.206 2.206 0 01-2.207-2.206zM7.944 22.58a2.207 2.207 0 00-2.207-2.207h-3.53a2.207 2.207 0 100 4.413h3.531a2.206 2.206 0 002.206-2.206zM32.928 32.93a2.208 2.208 0 013.121 0l2.497 2.497a2.205 2.205 0 11-3.121 3.12l-2.497-2.497a2.205 2.205 0 010-3.12zM12.231 12.231a2.207 2.207 0 000-3.121L9.734 6.614a2.207 2.207 0 00-3.121 3.12l2.497 2.497a2.205 2.205 0 003.121 0z"
      ></path>
    </svg>
  )
}

export function DarkModeIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      data-name="Layer 4"
      viewBox="0 0 32 32"
    >
      <path
        fill="#fff"
        d="M30.706 19.721a1 1 0 00-1.042-.234A13.423 13.423 0 0112.513 2.335a1 1 0 00-1.276-1.278A15.214 15.214 0 005.51 4.68a15.422 15.422 0 0021.81 21.81 15.214 15.214 0 003.623-5.728 1 1 0 00-.237-1.041z"
      ></path>
    </svg>
  )
}

export function DownloadLogsIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="20"
      height="20"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#fff"
        d="M32 464V48c0-8.837 7.163-16 16-16h240v64c0 17.673 14.327 32 32 32h64v48h32v-64a15.997 15.997 0 00-4.64-11.36l-96-96A16.001 16.001 0 00304 0H48C21.49 0 0 21.491 0 48v416c0 26.51 21.49 48 48 48h112v-32H48c-8.836 0-16-7.163-16-16zM480 320h-32v32h32v32h-64v-96h96c0-17.673-14.327-32-32-32h-64c-17.673 0-32 14.327-32 32v96c0 17.673 14.327 32 32 32h64c17.673 0 32-14.327 32-32v-32c0-17.673-14.327-32-32-32z"
      ></path>
      <path
        fill="#fff"
        d="M304 256c-35.346 0-64 28.654-64 64v32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64v-32c0-35.346-28.654-64-64-64zm32 96c0 17.673-14.327 32-32 32-17.673 0-32-14.327-32-32v-32c0-17.673 14.327-32 32-32 17.673 0 32 14.327 32 32v32zM160 256h-32v144c0 8.837 7.163 16 16 16h80v-32h-64V256z"
      ></path>
    </svg>
  )
}

export function TwitterTweetIcon(props: React.SVGAttributes<SVGElement>) {
  const { fill, ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        // fill="#2EDCF4"
        fill={props.fill ?? '#ffffff'}
        d="M20 3.642c-.75.344-1.5.601-2.333.687.833-.515 1.5-1.374 1.833-2.32-.833.516-1.667.86-2.583 1.032a3.981 3.981 0 00-3-1.375c-2.25 0-4.084 1.89-4.084 4.21 0 .343 0 .687.084.945-3.5-.172-6.5-1.89-8.5-4.468C1 3.041.833 3.728.833 4.501c0 1.46.75 2.75 1.834 3.522-.667 0-1.334-.171-1.834-.515v.086c0 2.062 1.417 3.78 3.25 4.124-.333.085-.666.171-1.083.171-.25 0-.5 0-.75-.085.5 1.718 2 2.92 3.833 2.92C4.667 15.841 2.917 16.53 1 16.53c-.333 0-.667 0-1-.086 1.833 1.202 4 1.89 6.25 1.89 7.583 0 11.667-6.444 11.667-12.028V5.79c.833-.602 1.5-1.375 2.083-2.148z"
      ></path>
    </svg>
  )
}

export function CheckmarkIcon(props: React.SVGAttributes<SVGElement>) {
  const { fill, ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill={fill ?? '#ffffff'}
        d="M7.6 10.4L3.6 8 2 9.6 7.6 16 18 5.6 16.4 4l-8.8 6.4z"
      ></path>
    </svg>
  )
}

export function PlayIcon(props: IconProps) {
  const fillColor = props.active ? '#8EF42E' : '#ffffff'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      fill="none"
      viewBox="0 0 46 46"
      {...props}
    >
      <g clipPath="url(#clip0_679_5687)">
        <path
          fill={fillColor}
          d="M42.1 20.7L13.35.577c-2.012-1.438-4.6 0-4.6 2.3v40.25c0 2.3 2.588 3.737 4.6 2.3L42.1 25.3c1.438-1.15 1.438-3.45 0-4.6z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_679_5687">
          <path fill="#fff" d="M0 0H46V46H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export function PauseIcon(props: IconProps) {
  const fillColor = props.active ? '#8EF42E' : '#ffffff'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      enableBackground="new 0 0 47.607 47.607"
      version="1.1"
      viewBox="0 0 47.607 47.607"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={fillColor}
        d="M17.991 40.976a6.631 6.631 0 01-13.262 0V6.631a6.631 6.631 0 0113.262 0v34.345zM42.877 40.976a6.631 6.631 0 01-13.262 0V6.631a6.631 6.631 0 0113.262 0v34.345z"
      ></path>
    </svg>
  )
}

export function RewindIcon(props: IconProps) {
  const fillColor = props.active ? '#8EF42E' : '#ffffff'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill={fillColor}
        d="M5 11.25c0 2.757 2.243 5 5 5s5-2.243 5-5-2.243-5-5-5V10L3.75 5 10 0v3.75c4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5-7.5-3.364-7.5-7.5H5z"
      ></path>
    </svg>
  )
}

export function LinkIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
      <path
        d="M21.0002 7.42701V10.8446C21.9522 11.3998 22.6002 12.4206 22.6002 13.5998V16.7998C22.6002 18.5646 21.165 19.9998 19.4002 19.9998H13.0002C11.2354 19.9998 9.80019 18.5646 9.80019 16.7998V13.5998C9.80019 11.835 11.2354 10.3998 13.0002 10.3998H14.6002V7.19981H13.0002C9.47059 7.19981 6.60019 10.0702 6.60019 13.5998V16.7998C6.60019 20.3294 9.47059 23.1998 13.0002 23.1998H19.4002C22.9298 23.1998 25.8002 20.3294 25.8002 16.7998V13.5998C25.8002 10.6254 23.7538 8.14221 21.0002 7.42701Z"
        fill="#8EF42E"
      />
      <path
        d="M13.0002 0.799805H6.60019C3.0706 0.799805 0.200195 3.6702 0.200195 7.19981V10.3998C0.200195 13.3742 2.2466 15.8574 5.0002 16.5726V13.155C4.0482 12.5998 3.4002 11.579 3.4002 10.3998V7.19981C3.4002 5.43501 4.83539 3.9998 6.60019 3.9998H13.0002C14.765 3.9998 16.2002 5.43501 16.2002 7.19981V10.3998C16.2002 12.1646 14.765 13.5998 13.0002 13.5998H11.4002V16.7998H13.0002C16.5298 16.7998 19.4002 13.9294 19.4002 10.3998V7.19981C19.4002 3.6702 16.5298 0.799805 13.0002 0.799805Z"
        fill="#8EF42E"
      />
    </svg>
  )
}

export function EyeIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      x="0"
      y="0"
      enableBackground="new 0 0 488.85 488.85"
      version="1.1"
      viewBox="0 0 488.85 488.85"
      xmlSpace="preserve"
    >
      <path
        fill="#fff"
        d="M244.425 98.725c-93.4 0-178.1 51.1-240.6 134.1-5.1 6.8-5.1 16.3 0 23.1 62.5 83.1 147.2 134.2 240.6 134.2s178.1-51.1 240.6-134.1c5.1-6.8 5.1-16.3 0-23.1-62.5-83.1-147.2-134.2-240.6-134.2zm6.7 248.3c-62 3.9-113.2-47.2-109.3-109.3 3.2-51.2 44.7-92.7 95.9-95.9 62-3.9 113.2 47.2 109.3 109.3-3.3 51.1-44.8 92.6-95.9 95.9zm-3.1-47.4c-33.4 2.1-61-25.4-58.8-58.8 1.7-27.6 24.1-49.9 51.7-51.7 33.4-2.1 61 25.4 58.8 58.8-1.8 27.7-24.2 50-51.7 51.7z"
      ></path>
    </svg>
  )
}

export function AnonAvatarIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      {...props}
    >
      <rect width="30" height="30" x="1" y="1" fill="#B8BCCF" rx="15"></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20 11a4 4 0 11-8 0 4 4 0 018 0zm3.686 8.119c.207.322.316.697.314 1.08V23H8v-2.8a1.989 1.989 0 011.163-1.818A16.651 16.651 0 0116 17a16.651 16.651 0 016.837 1.382c.348.16.643.415.85.738z"
        clipRule="evenodd"
      ></path>
      <rect width="30" height="30" x="1" y="1" stroke="#9C9DA8" strokeWidth="2" rx="15"></rect>
    </svg>
  )
}
